import { useScrollDirection } from '@/utils/businessLogics/_common/useScrollDirection';
import { useWindowScrollY } from '@/utils/businessLogics/util-browser';
import Gnb from '@/components/molecules/GNB';

import * as S from './LandingPageLnbOrganism.style';

export default function LandingPageLnbOrganism({
  ssrOnlineUserId,
  fixed = false,
}: {
  ssrOnlineUserId?: string;
  fixed?: boolean;
}) {
  const scrollY = useWindowScrollY();
  const direction = useScrollDirection();

  return (
    <>
      <S.LnbContents
        fixed={fixed}
        scrollY={scrollY}
        scrollDirection={direction}
      >
        <Gnb
          fixed={fixed}
          scrollY={scrollY}
          ssrOnlineUserId={ssrOnlineUserId}
        />
      </S.LnbContents>
    </>
  );
}
