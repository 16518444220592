import styled from '@emotion/styled';

import { device } from '@/styles/themes';

export const LnbContents = styled.div<{
  fixed: boolean;
  scrollY: number;
  scrollDirection: string;
}>`
  height: ${({ fixed, scrollY, scrollDirection }) => {
    if (fixed) {
      return '50px';
    }
    return scrollY === 0 || scrollDirection === 'down' ? '0' : '50px';
  }};
  ${({ fixed, scrollY }) =>
    fixed || scrollY > 0
      ? 'background: rgba(44, 45, 46, 0.8);backdrop-filter: blur(10px);'
      : ''};
  position: fixed;
  z-index: 20;
  width: 100%;
  transition: all 0.4s ease;
  top: 0;

  @media ${device.desktop} {
    height: ${({ fixed, scrollY, scrollDirection }) => {
      if (fixed) {
        return '60px';
      }
      return scrollY === 0 || scrollDirection === 'down' ? '0' : '60px';
    }};
  }
`;
